<template>
  <div feature v-editable="blok" :class="featureClass" @click="onClick($event)" :data-style="blok.style || 'icon'" :data-spacing="blok.spacing || 'default'">
    <template v-if="blok.style === 'columns'">
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''" v-html="description"></div>
    </template>
    <template v-else-if="blok.style === 'card'">
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''">
        <div v-html="description"></div>
      </div>
      <img loading="lazy" v-if="blok.icon?.filename" :src="optimizeImage(blok.icon.filename)" :alt="`Feature Icon ${blok.icon.alt}`" class="feature-icon" />
      <div v-if="blok.buttons && blok.buttons.length > 0" class="feature-buttons">
        <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
      </div>
    </template>
    <template v-else-if="blok.style === 'labeled'">
      <div class="feature-image">
        <img loading="lazy" v-if="blok.image.filename" :src="optimizeImage(blok.image.filename)" :alt="`Feature Image ${blok.image.alt}`" class="w-full object-cover" />
      </div>
      <div class="feature-content">
        <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
        <div class="feature-description" v-if="blok?.description && description != ''">
          <div v-html="description"></div>
        </div>
        <div v-if="blok.buttons && blok.buttons.length > 0" class="feature-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
      <div class="feature-label" v-if="blok?.name" v-text="blok?.name"></div>
    </template>
    <template v-else-if="blok.style === 'step'">
      <img loading="lazy" v-if="blok.icon?.filename" :src="optimizeImage(blok.icon.filename)" :alt="`Feature Icon ${blok.icon.alt}`" class="feature-icon" />
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''" v-html="description"></div>
      <div v-if="blok.buttons && blok.buttons.length > 0" class="feature-buttons">
        <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
      </div>
    </template>
    <template v-else-if="blok.style === 'image'">
      <h3 class="feature-title" v-if="blok?.title && title != ''" v-html="title"></h3>
      <div class="feature-description" v-if="blok?.description && description != ''" v-html="description"></div>
      <img loading="lazy" v-if="blok.image?.filename" :src="optimizeImage(blok.image.filename)" :alt="`Feature image ${blok.image.alt}`" class="feature-image" />
      <div v-if="blok.buttons && blok.buttons.length > 0" class="feature-buttons">
        <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
      </div>
    </template>
    <template v-else>
      <img loading="lazy" v-if="blok.image.filename" :src="optimizeImage(blok.image.filename)" :alt="`Feature Image ${blok.image.alt}`" class="w-full h-48 xl:h-72 object-cover" />
      <div class="px-12 py-6">
        <h3 class="text-2xl text-[#1d243d] font-bold">
          {{ blok.name }}
        </h3>
      </div>
    </template>
  </div>
</template>

<script setup>
  const props = defineProps({ blok: Object });

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));

  const featureClass = computed(() => {
    if (props.blok.style === "columns") return ["feature-item"];
    if (["card", "image", "step", "labeled"].includes(props.blok.style)) {
      if (props.blok.buttons && props.blok.buttons.length > 0) return ["feature-card", "feature-card--clickable"];
      return ["feature-card"];
    }

    return ["w-full", "bg-[#f7f6fd]", "rounded-[5px]", "text-center", "overflow-hidden"];
  });

  const onClick = (event) => {
    if (props.blok.style === "labeled"){
      const buttons = event.currentTarget.querySelectorAll(".btn");
      if (buttons[0]) {
        event.preventDefault();
        console.log(buttons[0].href);

        const btnHash = buttons[0].href.startsWith('#') ? buttons[0].href : new URL(buttons[0].href)?.hash;
        if(btnHash){
          const btnTargetElement = document.querySelector(btnHash);
          if(btnTargetElement){
            btnTargetElement.scrollIntoView({ behavior: "smooth" });
          }
        }
      }

      return;
    };
    if (props.blok.style !== "card") return;
    if (event.target !== event.currentTarget) return;
    if (props.blok.buttons?.length > 0) {
      event.preventDefault();
      const buttons = event.target.querySelectorAll(".btn");
      console.log(event.target, buttons);
      if (buttons[0]) {
        buttons[0].click();
      }
    }
  };
</script>
